<template>
  <v-dialog
      v-model="dialog"
      width="1000"
      v-if="currentCase.customerMailAddress"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn   v-bind="attrs"
               v-on="on" icon>
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="primary">
        E-Mail Versand
      </v-card-title>
      <v-card-subtitle class="mt-4">Ansprache und Signatur werden automatisch beim senden hinzugefügt.</v-card-subtitle>
      <v-card-subtitle><strong>Betreff:</strong> Nachricht von Ihrem MPU Betreuer </v-card-subtitle>
      <v-card-subtitle><strong>Name des Kunden:</strong> {{currentCase.customerName + " " + currentCase.customerLastname}}</v-card-subtitle>
      <v-card-text >
        <v-textarea v-model="mailText" v-on:input="saveToLocalStorage()" :counter="8000" label="E-Mail Text" outlined></v-textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
            :disabled="mailText.length === 0 || mailText.length > 8000"
            color="primary"
            @click="sendMail()"
        >
          Absenden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";

export default {
  name: "CustomerMailDialog",
  props: {
    currentCase: {
      required: true
    },
    note: {
      required: true
    },
    controllerURL: {
      required: true
    },
  },
  data: () => ({
    dialog: false,
    mailText: "",
  }),
  mounted() {
    if (localStorage.getItem('CustomerMailDialog' + this.currentCase.id)) {
      this.recoverData()
    }
  },
  methods: {

    clearRecoverData() {
      localStorage.setItem('CustomerMailDialog' + this.currentCase.id, "")
    },
    recoverData() {
      this.mailText = localStorage.getItem('CustomerMailDialog' + this.currentCase.id)
    },
    saveToLocalStorage(){
      localStorage.setItem('CustomerMailDialog' + this.currentCase.id, this.mailText)
    },
    async sendMail() {
        await sessionHandler();
        await this.$http
            .patch(
                this.controllerURL + "Note" + "/" + this.note.id + "/SendToCustomer",
                { "mailText": this.mailText }
                , {
                  mode: requestMode(),
                  headers: {
                    'Accept': 'application/json',
                    'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                  },
                }
            ).then(response => {
              this.mailText = "";
              this.dialog = false;
              this.$emit("updateNotes", response.data);
              showAlert("Die E-Mail wurde erfolgreich versendet", "success");
            }).catch(error => {
              console.log(error);
            });
    },
  }
}
</script>

<style scoped>

</style>
