<template>
  <v-dialog v-model="dialog" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on" :color="color">
        <v-icon>
          mdi-calendar-range-outline
        </v-icon>
      </v-btn>
    </template>
    <v-app-bar fixed color="primary"> <v-btn class="mr-2" icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn> <h3 >Termine für {{ currentCase.customerName }} {{ currentCase.customerLastname }}</h3></v-app-bar><v-main style="padding-bottom: 66px">
      <v-card style="height: 90vh">
        <v-card-text>
          <v-timeline>
            <v-timeline-item left v-for="event in events" v-bind:key="'Event' + event.id">
              <template v-slot:opposite>
          <span
              class="headline font-weight-bold text--primary"
          >
            {{ dateFormatter(event.start) + " " +   new Date(event.start).toISOString().substring(11, 16) }}
          </span>
              </template>
              <event v-bind:currentCase="currentCase" v-bind:event="event" v-bind:controllerURL="controllerURL" v-on:updateNotes="getAppointments()" />
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              fixed
              class="mb-10"
              fab
              large
              bottom
              :disabled="currentCase.numberOfAssignedAppointments === currentCase.numberOfBookedAppointments"
              color="secondary"
              right
              :to="'/calendar?mpu=true&caseId=' + currentCase.id"
          ><v-icon>mdi-link</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-main>
   <core-footer />
  </v-dialog>
</template>

<script>
import {requestMode} from "@/configBuilder";
import {sessionHandler} from "@/request/sessionHandler";
import CoreFooter from "@/components/Core/Footer";
import Event from "@/components/dialogs/event/Event";
import dateFormatter from "@/utils/dateFormatter";
export default {
  name: "ViewLinkedEventsDialog",
  components: {Event, CoreFooter},
  props: {
    controllerURL: {
      required: true
    },
    currentCase: {
      required: true
    },
    color: {
      default: "primary"
    }
  },
  data: () => ({
    dialog: false,
    events: []
  }),
  methods: {
    dateFormatter(date){
      return dateFormatter(date);
    },
    async getAppointments() {
      await sessionHandler();
      await this.$http.get(this.controllerURL + "/" + this.currentCase.id + "/Appointments" , {
        mode: requestMode(),
        headers: {
          'Accept': 'application/json',
          'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
        },
      })
          .then(response => {
            this.events = response.data;
          })
          .catch(error => {
            console.log(error);
          });
    }
  },
  mounted() {
    this.getAppointments();
  }
}
</script>

<style scoped>

</style>
