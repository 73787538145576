<template>
  <v-card outlined>
    <v-card-title class="primary">
      {{ event.name }}
      <v-spacer></v-spacer>
    </v-card-title >
    <v-card-text class="text-body-1 white--text mt-3">
      {{ dateFormatter(event.start) + " " +   new Date(event.start).toISOString().substring(11, 16) + " bis " + new Date(event.end).toISOString().substring(11, 16) }}
    </v-card-text>
    <v-divider></v-divider>
    <v-card-text>
        <v-expansion-panels focusable >
          <v-expansion-panel>
            <v-expansion-panel-header color="primary">
              Alle Fälle in diesem Termin
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pt-5">
            <show-appointments-by-appointment-id v-bind:color="'primary'" v-bind:appointment-id="event.id"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


    </v-card-text>
  </v-card>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import dateFormatter from "@/utils/dateFormatter";
import ShowAppointmentsByAppointmentId from "@/components/MPU/ShowAppointmentsByAppointmentId";

export default {
  name: "Event",
  components: {
    ShowAppointmentsByAppointmentId
  },
  props: {
    event: {
      required: true
    },
    currentCase: {
      required: true
    },
    controllerURL: {
      required: true
    },
  },
  data: () => ({

  }),
  methods: {
    dateFormatter(date){
      return dateFormatter(date);
    },
    async sendToCustomer() {
      await sessionHandler();
      await this.$http
          .patch(
              this.controllerURL + "Note" + "/" + this.note.id + "/SendToCustomer",
              {}
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          ).then(response => {
        this.$emit("updateNotes", response.data);
        showAlert("Die E-Mail wurde erfolgreich versendet", "success");
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

</style>
