<template>
  <v-row>
    <v-col v-for="currentCase in cases" v-bind:key="currentCase.id" cols="12">
      <v-card :loading="isLoading" :color="color">
        <v-card-title>
          {{ currentCase.customerName }} {{ currentCase.customerLastname }}
        </v-card-title>
        <v-card-subtitle class="text--white">
          {{ dateFormatter(currentCase.createdAt) + " " +   new Date(currentCase.createdAt).toISOString().substring(11, 16) }}
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getMpuURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import dateFormatter from "@/utils/dateFormatter";

export default {
  name: "ShowAppointmentsByAppointmentId",
  props: {
    appointmentId: {
      type: Number,
      required: true
    },
    color: {
      default: "blue"
    }
  },
  data: () => ({
    isLoading: false,
    cases: [],
  }),
  methods: {
    dateFormatter(date){
      return dateFormatter(date);
    },
    async getCases(){
      this.isLoading = true
      let parameter = {
        'appointmentId': this.appointmentId,
      }
      await sessionHandler();
      await this.$http
          .get(
              getMpuURL(),
              {
                mode: requestMode(),
                params: parameter,
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.cases = response.data
            } else {
              showAlert(i18n.t('error.api.undefined') + "MPUCaseView-getOpenCases-1-admin=" + this.admin, "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "MPUCaseView-getOpenCases-2-admin=" + this.admin, "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },
  },
  mounted() {
    this.getCases();
  },
  watch: {
    appointmentId: function () {
      this.getCases();
    }
  }
}
</script>

<style scoped>

</style>
