<template>
  <v-container>
    <div>
      <v-card :loading="isLoading" v-if="hasViewPermission || admin">
        <v-card-title>
          {{ insurance }}
        </v-card-title>
        <v-card-text>
          <v-expansion-panels v-model="panel.open">
            <v-expansion-panel>
              <v-expansion-panel-header color="secondary">
                Offene {{ name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content color="primary" > <!-- TODO if > 0 else "Es gibt aktuell keine ... Fälle." -->
                <v-row class="fill-height body mt-2">
                  <v-col cols="12" md="4" v-for="openCase in openCases" v-bind:key="openCase.id" :id="openCase.id" ref="openCases">
                    <open-case-card v-bind:dataTypeName="dataTypeName" v-bind:allowDataEdit="allowDataEdit" v-bind:controllerURL="controllerURL" v-bind:openCase="openCase" v-bind:dataType="dataType" v-on:getCases="getCases()"/>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels v-model="panel.closed" class="mt-3">
            <v-expansion-panel>
              <v-expansion-panel-header color="error">
                Geschlossene {{ name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content color="error darken-2">  <!-- TODO if > 0 else "Es gibt aktuell keine ... Fälle." -->
                <v-row class="fill-height body mt-2">
                  <v-col cols="12" md="4" v-for="closedCase in closedCases" v-bind:key="closedCase.id">
                    <closed-case-card v-bind:controllerURL="controllerURL" v-bind:dataTypeName="dataTypeName" v-bind:closedCase="closedCase" v-on:getCases="getCases()" />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
      <v-card v-if="!hasViewPermission && !admin">
        <v-card-title>Sie haben keine Berechtigung diese Seite zu sehen.</v-card-title>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import reactiveStorage from "@/plugins/reactiveStorage";
import OpenCaseCard from "@/components/MPU/cards/OpenMPUCaseCard";
import ClosedCaseCard from "@/components/MPU/cards/ClosedMPUCaseCard";
// import UnassignedCaseCard from "@/components/MPU/cards/UnassignedMPUCardCard";

export default {
  name: "MPUCaseView",
  components: { OpenCaseCard, ClosedCaseCard},
  props: {
    insurance: {
      required: true,
    },
    permission: {
      required: true,
    },
    dataType: {
      required: true,
    },
    dataTypeName: {
      required: true
    },
    name: {
      required: true,
    },
    controllerURL: {
      required: true,
    },
    rebindPermission: {
      required: true
    },
    allowDataEdit: {
      required: true
    },
  },
  data: () => ({
    isLoading: false,
    openCases: [],
    closedCases: [],
    unassignedCases: [],
    admin: reactiveStorage.user.roles.Admin,
    panel: {
        open: null,
        closed: null,
    },
    hasViewPermission: false
  }),
  methods: {
    //dialog
    getCases(){
        this.getClosedCases()
        this.getOpenCases()
    },
    async getOpenCases(){
      this.isLoading = true
      let parameter = {
        'isClosed': "false",
      }
      await sessionHandler();
      await this.$http
          .get(
              this.controllerURL,
              {
                mode: requestMode(),
                params: parameter,
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
                this.openCases = response.data
            } else {
              showAlert(i18n.t('error.api.undefined') + "MPUCaseView-getOpenCases-1-admin=" + this.admin, "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "MPUCaseView-getOpenCases-2-admin=" + this.admin, "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.scrollToId()
          })
    },
    async getClosedCases(){
      this.isLoading = true
      let parameter = {
        'isClosed': "true",
      }
      await sessionHandler();
      await this.$http
          .get(
              this.controllerURL
              , {
                mode: requestMode(),
                params: parameter,
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
                this.closedCases = response.data
                this.closedCases = this.closedCases.reverse();
            } else {
              showAlert(i18n.t('error.api.undefined') + "MPUCaseView-getClosedCases-1-admin=" + this.admin, "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "MPUCaseView-getClosedCases-2-admin=" + this.admin, "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.scrollToId()
          })
    },
    async jumpTo(id){
      await new Promise(resolve => setTimeout(resolve, 400));
      const yOffset = -70;
      const element = document.getElementById(id);
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    },
    scrollToId(){
        if(this.openCases.filter(item => { return item.id === +this.$route.query.id }).length === 1){
          this.panel.open = 0;
          this.jumpTo(this.$route.query.id);
        }
        else if(this.closedCases.filter(item => { return item.id === +this.$route.query.id }).length === 1){
          this.panel.closed = 0;
          this.jumpTo(this.$route.query.id);
        }
    },
    initData(){
      this.hasViewPermission = reactiveStorage.user.permissions.MPU.MPU;
    }
    },
  mounted() {
    if (this.$route.query.caseId) {
      this.panel.open = 0;
    }
    // needs to be first
    this.initData()
    this.getCases()
  },
  computed: {

  },
  watch: {
    '$route.query.id': {
      handler(){ this.scrollToId() },
      deep: true,
      immediate: true
    }
  },
}
</script>

<style scoped>

</style>
