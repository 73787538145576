<template>
  <v-dialog
      v-model="dialog"
      fullscreen
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          class="ml-1"
          icon
          v-bind="attrs"
          v-on="on" color="primary">
        <v-icon
            left
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <template-form v-on:closeDialog="closeDialog()" ref="templateForm" v-bind:name="name" v-bind:form-fields="formFields" v-bind:url="url" v-bind:localStorageKey="'MPUUpdateForm' + currentCase.id" v-bind:currentCase="currentCase" v-bind:update="true" />
      </v-card-text>
    </v-card>
    </v-dialog>
</template>

<script>

import TemplateForm from "@/components/TemplateForm";
import {getContactsURL, getMpuURL} from "@/configBuilder";
export default {
  name: "AddToMPU",
  components: {TemplateForm },
  props: {
    currentCase: {
      default: false,
    },
  },
  data: () => ({
    name: "MPU Falldaten anpassen",
    url: getMpuURL(),
    isLoading: false,
    dialog: false,
  }),
  methods: {
    syncHTMLRenderer() {
      this.$forceUpdate();
    },
    closeDialog() {
      this.dialog = false
      this.$emit('updateCases')
    }

  },
  computed: {
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    formFields(){
      return [
        {name: "Allgemeine Kundeninformationen", color: "background--lighten-1", flat: true, md: 12, cols:  [
            {
              model: this.currentCase.customerName ? this.currentCase.customerName : "",
              name: "customerName",
              type: "textField",
              label: "Vorname",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: this.currentCase.customerLastname ? this.currentCase.customerLastname : "",
              name: "customerLastname",
              type: "textField",
              label: "Nachname",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: this.currentCase.customerMailAddress ? this.currentCase.customerMailAddress : "",
              name: "customerMailAddress",
              type: "textField",
              label: "E-Mail",
              color: "",
              rules: [
                v => /^\S+@\S+\.\S+$/.test(v) || this.errorMessage["validEmail"],
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: this.currentCase.customerMobileNumber ? this.currentCase.customerMobileNumber : "",
              name: "customerMobileNumber",
              type: "textField",
              label: "Telefonnummer",
              color: "",
              fieldType: "",
              rules: [
                v => /\+[0-9]{2}[1-9][0-9]{1,25}/.test(v) || this.errorMessage["validPhone"],
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
          ],},
        {name: "Wohnort des Kunden", color: "background--lighten-1", flat: false, md: 12, cols:  [
            {
              model: this.currentCase.customerStreet ? this.currentCase.customerStreet : "",
              name: "customerStreet",
              type: "textField",
              label: "Strasse",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: this.currentCase.customerStreetnumber ? this.currentCase.customerStreetnumber : "",
              name: "customerStreetnumber",
              type: "textField",
              label: "Hausnummer",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: this.currentCase.customerZipCode ? this.currentCase.customerZipCode : "",
              name: "customerZipCode",
              type: "textField",
              fieldType: "number",
              label: "Postleitzahl",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
            {
              model: this.currentCase.customerCity ? this.currentCase.customerCity : "",
              name: "customerCity",
              type: "textField",
              label: "Stadt",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              counter: 0,
              autoGrow: false,
              required: true,
              md: 3,
            },
          ],},
        {name: "Falldaten", color: "background--lighten-1", flat: false, md: 12, cols:  [
            {
              model: this.currentCase.reason ? this.currentCase.reason : "",
              name: "reason",
              type: "textarea",
              label: "Grund",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
                v => (v && v.length <= 8000) || "Notiz darf nicht länger als 8000 Zeichen sein",
              ],
              counter: 8000,
              autoGrow: false,
              required: true,
              md: 12,
            },
            {
              model: this.currentCase.numberOfBookedAppointments ? this.currentCase.numberOfBookedAppointments : "",
              name: "numberOfBookedAppointments",
              type: "textField",
              label: "Anzahl an buchbaren Terminen",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
                v => (v && v > 0 && v < 100) || "",
              ],
              counter: 2,
              autoGrow: false,
              required: true,
              md: "auto",
            },
          ],},
        {name: "Vermittler", color: "background--lighten-2", flat: false, md: 12, cols:  [
            {
              model: this.currentCase.intermediaryAgent ? this.currentCase.intermediaryAgent : "",
              name: "intermediaryAgent",
              type: "combobox",
              label: "Vermittler",
              items: [],
              itemText: "name",
              itemFoundInItems: false,
              createURL: getContactsURL(),
              createFields: [
                {
                  model: "",
                  name: "mailAddress",
                  type: "textField",
                  label: "E-Mail",
                  color: "",
                  rules: [
                    v => /^\S+@\S+\.\S+$/.test(v) || this.errorMessage["validEmail"],
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "mobileNumber",
                  type: "textField",
                  label: "Telefonnummer",
                  color: "",
                  fieldType: "",
                  rules: [
                    v => /\+[0-9]{2}[1-9][0-9]{1,25}/.test(v) || this.errorMessage["validPhone"],
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "street",
                  type: "textField",
                  label: "Strasse",
                  color: "",
                  rules: [
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "streetnumber",
                  type: "textField",
                  label: "Hausnummer",
                  color: "",
                  rules: [
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "zipCode",
                  type: "textField",
                  fieldType: "number",
                  label: "Postleitzahl",
                  color: "",
                  rules: [
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
                {
                  model: "",
                  name: "city",
                  type: "textField",
                  label: "Stadt",
                  color: "",
                  rules: [
                    v => !!v || this.errorMessage.required,
                  ],
                  counter: 0,
                  autoGrow: false,
                  required: true,
                  md: 3,
                },
              ],
              search: "",
              color: "",
              rules: [
                v => !!v || this.errorMessage.required,
              ],
              autoGrow: false,
              required: false,
              md: 12,
            },
          ],},
      ]
    },
  },
}
</script>

<style scoped>

</style>
