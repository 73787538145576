<template>
  <span>
    <span v-if="!show">{{ text.substring(0, cuttingCount) }}<span  v-if="text.length > cuttingCount">...</span></span>
    <span v-else>{{ text }}</span>
    <v-btn
        v-if="text.length > cuttingCount"
        text
        class="mb-1"
        small
        @click="show = !show"
    >
      <span v-if="!show">mehr</span>
      <span v-else>weniger</span>
    </v-btn>
  </span>
</template>

<script>
export default {
  name: "ViewMore",
  props: {
    text: {
      type: String,
      required: true
    },
    cuttingCount: {
      type: Number,
      default: 400
    }
  },
  data: () => ({
    show: false,
  }),
}
</script>

<style scoped>

</style>
