<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-if="fab"
          fixed
          class="mb-10"
          fab
          large
          bottom
          right
          v-bind="attrs"
          v-on="on" color="secondary">
        <v-icon
        >
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn
          v-else
          icon
          v-bind="attrs"
          v-on="on" :color="color">
        <v-icon
        >
          mdi-note-plus
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="primary">
        Notiz hinzufügen
      </v-card-title>
      <v-card-text >
        <v-form
            :lazy-validation="lazy"
            class="form"
            ref="form"
            v-model="valid"
        >
          <v-col cols="12">
            <v-textarea
                v-on:input="saveToLocalStorage()"
                counter
                :label="inputLabels.note"
                required
                :rules="noteRules"
                v-model="note"
            ></v-textarea>
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="error"
            text
            @click="dialog = false"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="primary"
            text
            :disabled="!valid"
            @click="saveNote()"
        >
          Notiz hinzufügen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {requestMode} from "@/configBuilder";

export default {
  name: "AddNoteToCase",
  props: {
    controllerURL: {
      required: true
    },
    currentCase: {
      required: true
    },
    fab: {
      type: Boolean,
      default: false
    },
    color: {
      default: "primary"
    }
  },
  data: () => ({
    note: "",
    dialog: false,
    valid: true,
    lazy: false,
    inputLabels: {
      note: "Notiz"
    },
  }),
  mounted() {
    if (localStorage.getItem('AddNoteToCase' + this.currentCase.id)) {
      this.recoverData()
    }
  },
  methods: {
    clearRecoverData() {
      localStorage.setItem('AddNoteToCase' + this.currentCase.id, "")
    },
    recoverData() {
      this.note = localStorage.getItem('AddNoteToCase' + this.currentCase.id)
    },
    saveToLocalStorage(){
      localStorage.setItem('AddNoteToCase' + this.currentCase.id, this.note)
    },
    updateNotes() {
      this.$emit("updateNotes");
    },
    async saveNote() {
      await sessionHandler();
      await this.$http.post(this.controllerURL + "/" + this.currentCase.id + "/Note" , { "note": this.note }, {
        mode: requestMode(),
        headers: {
          'Accept': 'application/json',
          'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
        },
      })
          .then(response => {
            this.dialog = false;
            this.note = "";
            this.clearRecoverData();
            this.updateNotes(response.data);
          })
          .catch(error => {
            console.log(error);
          });
    }
  },
  computed: {
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    noteRules() {
      return [
        v => !!v || this.errorMessage.required,
        v => v.length <= 8000 || "Notiz darf nicht länger als 8000 Zeichen sein"
      ];
    },
  },
}
</script>

<style scoped>

</style>
