<template>
  <v-dialog v-model="uploadDialog" max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on" color="primary">
        <v-icon>
          mdi-upload
        </v-icon>
      </v-btn>
    </template>
    <v-card >
      <v-card-title>
        Dokumente an den Fall anhängen
      </v-card-title>
      <v-card-text>
      <v-col>
        <v-file-input
            v-model="pictures"
            label="Dokument hochzuladen"
            :show-size="1000"
            accept="application/pdf,image/*"
            color="secondary"
            multiple
            counter
            required
            prepend-icon="mdi-file-document"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
                v-if="index < 20"
                color="accent"
                dark
                label
                small
            >
              {{ text }}
            </v-chip>

            <span
                v-else-if="index === 20"
                class="overline grey--text text--darken-3 mx-2"
            >
          +{{ pictures.length - 20 }} File(s)
        </span>
          </template>
        </v-file-input>
      </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="uploadButtonClick()">Dateien hochladen</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import {PdfService} from "@/utils/imgToPdf";
import {getMpuURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import {sessionHandler} from "@/request/sessionHandler";

export default {
  name: "MPUFileUpload",
  props: {
    caseId: {
      required: true
    },
    controllerURL: {
      required: true
    }
},
  data: () => ({
    pictures: [],
    key: "",
    uploadDialog: false,
  }),
  methods: {
    async upload(key){
      this.isLoading = true
      let formData = [];
      const documents = this.pictures.filter(img => img.type.split("/")[0] !== "image")
      const pictures = this.pictures.filter(img => img.type.split("/")[0] === "image")
      documents.forEach(doc => {
        let temp = new FormData()
        temp.append("file",doc)
        formData.push(temp)
      })
      if(pictures.length > 0){
        const service = new PdfService();
        const pdf = await service.generatePdf("GeneratedPDF", pictures)
        //pdf.open()
        pdf.getBlob(blob => {
          let temp = new FormData()
          temp.append("file",blob, "ConvertedImages.pdf")
          formData.push(temp)
          formData.forEach((fD, index) => {
            this.$http.post(
                getMpuURL() + "/" + key + "/Document",
                fD
            ).then(() => {
              if (index === formData.length-1){
                this.pictures = [];
                this.done = true;
                //ALERT
                showAlert("Die Dateien wurden dem Fall erfolgreich angehängt", "success")
                this.isLoading = false;
              }
            }).catch(error =>{
                  if (429 === error.response.status){
                    index = formData.length-1;
                    this.uploadLimitHit = true
                  }
                  if (412 === error.response.status){
                    index = formData.length-1;
                    this.sessionOutdated = true
                  }
                }
            )
          })
        })
      }
      if(pictures.length === 0){
        formData.forEach((fD, index) => {
          // console.log(formData)
          this.$http.post(
              getMpuURL() + "/" + key + "/Document",
              fD
          ).then(() => {
            if (index === formData.length-1){
              this.pictures = [];
              this.done = true;
              //ALERT
              showAlert("Vielen Dank", "success")
              this.isLoading = false;
            }
          }).catch(error =>{
                if (429 === error.response.status){
                  index = formData.length-1;
                  this.uploadLimitHit = true
                }
                if (412 === error.response.status){
                  index = formData.length-1;
                  this.sessionOutdated = true
                }
              }
          )
        })
      }
    },
    ownDecode(url){
      let temp = encodeURI(url);
      temp = temp.replaceAll(":", "%3A");
      temp = temp.replaceAll("/", "%2F");
      temp = temp.replaceAll("?", "%3F");
      temp = temp.replaceAll("#", "%23");
      temp = temp.replaceAll("[", "%5B");
      temp = temp.replaceAll("]", "%5D");
      temp = temp.replaceAll("@", "%40");
      temp = temp.replaceAll("$", "%24");
      temp = temp.replaceAll("&", "%26");
      temp = temp.replaceAll("+", "%2B");
      temp = temp.replaceAll(",", "%2C");
      temp = temp.replaceAll(";", "%3B");
      temp = temp.replaceAll("=", "%3D");
      return temp
    },
    async getUploadKey(){
      await sessionHandler();
      await this.$http.patch(
          getMpuURL() + "/" + this.caseId + "/ResendUploadLink",
          {},
          {
            mode: requestMode(),
            params: {sendMail: false, sendSMS: false},
            headers: {
              'Accept': 'application/json',
              'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
            },
          }
      ).then(response => {
        this.key = this.ownDecode(response.headers["x-key"])
      }).catch(error => {
        if (429 === error.response.status){
          this.uploadLimitHit = true
        }
        if (412 === error.response.status){
          this.sessionOutdated = true
        }
      })
    },
    async uploadButtonClick(){
      await this.getUploadKey();
      await this.upload(this.key)
      this.$emit('updateCases')
      this.uploadDialog = false;
    }
  }
}
</script>

<style scoped>

</style>
