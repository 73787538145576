<template>
  <v-dialog v-model="dialog" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on" :color="color">
        <v-icon>
          mdi-note-multiple
        </v-icon>
      </v-btn>
    </template>
    <v-app-bar fixed color="primary"> <v-btn class="mr-2" icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn> <h3 >Notizen</h3></v-app-bar><v-main style="padding-bottom: 66px">
      <v-card style="min-height: 90vh">
        <v-card-text>
          <v-timeline>
            <v-timeline-item left v-for="note in notes" v-bind:key="'Note' + note.id">
              <template v-slot:opposite>
          <span
              class="headline font-weight-bold text--primary"
              v-text="note.createdAt"
          ></span>
              </template>
              <note v-bind:currentCase="currentCase" v-bind:note="note" v-bind:controllerURL="controllerURL" v-on:updateNotes="getNotes()" />
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <add-note-to-case
              v-on:updateNotes="getNotes()"
              v-bind:fab="true"
              v-bind:controllerURL="controllerURL"
              v-bind:currentCase="currentCase"
          />
        </v-card-actions>
      </v-card>
    </v-main>
   <core-footer />
  </v-dialog>
</template>

<script>
import AddNoteToCase from "@/components/dialogs/notes/AddNoteToCase";
import {requestMode} from "@/configBuilder";
import {sessionHandler} from "@/request/sessionHandler";
import Note from "@/components/dialogs/notes/Note";
import CoreFooter from "@/components/Core/Footer";
export default {
  name: "ViewNotesDialog",
  components: {CoreFooter, Note, AddNoteToCase},
  props: {
    controllerURL: {
      required: true
    },
    currentCase: {
      required: true
    },
    color: {
      default: "primary"
    }
  },
  data: () => ({
    dialog: false,
    notes: []
  }),
  methods: {
    async getNotes() {
      await sessionHandler();
      await this.$http.get(this.controllerURL + "/" + this.currentCase.id + "/Notes" , {
        mode: requestMode(),
        headers: {
          'Accept': 'application/json',
          'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
        },
      })
          .then(response => {
            this.notes = response.data.reverse();
          })
          .catch(error => {
            console.log(error);
          });
    }
  },
  mounted() {
    //TODO FIX not working after craete maybe works with update
    this.getNotes();
  }
}
</script>

<style scoped>

</style>
