<template>
  <v-card outlined elevation="2">
    <v-card-title class="primary">
      {{ note.createdBy }}
      <v-spacer></v-spacer>
      <send-note-to-customer
          v-if="!note.mailText"
          v-bind:controllerURL="controllerURL" v-bind:currentCase="currentCase"
          v-bind:note="note" v-on:updateNotes="updateNotes()"/>
    </v-card-title >
    <v-card-text class="text-body-1 mt-3">
      <view-more v-bind:text="note.note"/>
    </v-card-text>
   <v-card-text>
     <v-card v-if="note.mailText" color="accent">
       <v-card-title>E-Mail</v-card-title>
       <v-card-text>
         <view-more v-bind:text="note.mailText"/>
       </v-card-text>
     </v-card>
   </v-card-text>
  </v-card>
</template>

<script>
import SendNoteToCustomer from "@/components/dialogs/notes/SendNoteToCustomer";
import ViewMore from "@/components/ViewMore";

export default {
  name: "Note",
  components: {
    ViewMore,
    SendNoteToCustomer
  },
  props: {
    note: {
      required: true
    },
    currentCase: {
      required: true
    },
    controllerURL: {
      required: true
    },
  },
  methods: {
    updateNotes() {
      this.$emit("updateNotes");
    }
  }
}
</script>

<style scoped>

</style>
